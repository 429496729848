@import "theme/module";

.wrapper {
    background: transparent;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
}

.box {
    font-family: "Roboto";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
        
    div {
        font-size: px-to-rem(24px);   
        a:hover {
            text-decoration: underline;
            // cursor: pointer;
        }

        &.h1 {
            font-size: px-to-rem(56px);
            padding-bottom: px-to-rem(48px);
        }
    }
}

